import pick from 'lodash/pick';
import Cookies from 'js-cookie';

/**
 * Write / read Marketo form data to / from a cookie.
 * Used to pre-fill Marketo form fields with previously submitted data.
 */
export const STORABLE_FIELDS = ['FirstName', 'LastName', 'Email', 'Company', 'Title', 'Country'] as const;
const COOKIE_NAME = 'sensor_tower_marketo_form_data';

/** Delete the cookie with submitted form data  */
export function clearSubmittedFormData() {
  Cookies.remove(COOKIE_NAME);
}

/** Store submitted form data in a cookie, if user gave permission for personalization */
export function storeSubmittedFormData(fields: Record<string, string>) {
  try {
    const cookieConsent = window.Osano.cm.getConsent();
    const canPersonalize = cookieConsent['PERSONALIZATION'] === 'ACCEPT';

    if (canPersonalize) {
      const fieldsToStore = pick(fields, STORABLE_FIELDS);
      Cookies.set(COOKIE_NAME, JSON.stringify(fieldsToStore), {
        sameSite: 'strict',
        secure: true,
        expires: 30,
      });
    }
  } catch {}
}

/** Read submitted form data in a cookie */
export function readSubmittedFormData(): Record<(typeof STORABLE_FIELDS)[number], string> | Record<string, never> {
  const cookie = Cookies.get(COOKIE_NAME);

  try {
    return cookie ? JSON.parse(cookie) : {};
  } catch {
    // Remove invalid cookie
    clearSubmittedFormData();
    return {};
  }
}
