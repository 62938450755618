import React from 'react';
import { useRouter } from 'next/router';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import type { STORABLE_FIELDS } from '../utils/knownVisitor';

// TODO: Provide these translations via Contentful
const welcomeTexts = {
  'en-US': 'Hi {firstName}, welcome back!',
  ja: 'おかえりなさい！',
  ko: '안녕하세요. {firstName} 님, 다시 오신 것을 환영합니다! ',
  'zh-CN': '您好{firstName}，欢迎回来！',
} as const;
const notYouTexts = {
  'en-US': 'Not you?',
  ja: '{firstName}様ではありませんか？',
  ko: '혹시 본인이 아니신가요?',
  'zh-CN': '请问是您本人吗？',
} as const;

type KnownVisitorProps = {
  formValues: Record<(typeof STORABLE_FIELDS)[number], string> | Record<string, never>;
  onClearFormValues: () => void;
};

export const KnownVisitor = ({ formValues, onClearFormValues }: KnownVisitorProps) => {
  const { locale } = useRouter();

  // It should not be possible for firtName to be empty, as there's a check in the parent component
  const firstName = formValues.FirstName || '';

  // @ts-expect-error - TS doesn't know that the locale is a string
  const welcomeText = (welcomeTexts[locale] || welcomeTexts['en-US']).replace('{firstName}', firstName);
  // @ts-expect-error - TS doesn't know that the locale is a string
  const notYouText = (notYouTexts[locale] || notYouTexts['en-US']).replace('{firstName}', firstName);

  return (
    <Stack spacing={1} direction="row" component="span">
      <Typography
        variant="h5"
        component="h5"
        fontStyle={(theme) => ({ color: `${theme.palette.primary.contrastText} !important` })}
      >
        {welcomeText}
      </Typography>

      <Typography
        variant="h5"
        component="h5"
        role="button"
        onClick={onClearFormValues}
        sx={(theme) => ({
          cursor: 'pointer',
          color: `${theme.palette.teal.main} !important`,
          '&:hover': {
            color: `${theme.palette.teal.dark} !important`,
          },
        })}
      >
        {notYouText}
      </Typography>
    </Stack>
  );
};
