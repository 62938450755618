import invalidDomains from './invalidDomains';

export function isEmailGood(email: string) {
  for (let i = 0; i < invalidDomains.length; i++) {
    let domain = invalidDomains[i];
    if (email.indexOf(`@${domain}`) != -1) {
      return false;
    }
  }
  return true;
}

export const invalidEmailErrorMessage = {
  'en-US': 'Must be a business email or please email us directly at bdrteam@sensortower.com',
  ko: '비즈니스용 이메일 주소를 사용해 주세요. 그 외의 이메일 주소는 bdrteam@sensortower.com으로 직접 이메일을 보내주세요.',
  ja: 'ビジネス用のメールアドレスをご使用ください。それ以外のメールアドレスの場合は、 bdrteam@sensortower.com へ直接メールを送信ください。',
  'zh-CN': '请使用商务电子邮件，或者直接发送邮件至 bdrteam@sensortower.com。',
};
