import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useRouter } from 'next/router';
import Script from 'next/script';

import Text from '../../Text';
import { Form, FormCircularProgress, FormWrapper, Root } from '../shared/components/BaseComponents';
import { KnownVisitor } from '../shared/components/KnownVisitor';
import { sidekick } from '../../../utils/sidekick';
import { addFieldModifiers } from '../shared/utils/addFieldModifiers';
import { isEmailGood, invalidEmailErrorMessage } from '../shared/utils/isEmailGood';
import {
  clearSubmittedFormData,
  readSubmittedFormData,
  STORABLE_FIELDS,
  storeSubmittedFormData,
} from '../shared/utils/knownVisitor';
import { logFormSubmitEvent } from '../shared/utils/tracking';

import type { BaseLeadFormProps } from '../shared/types';

export interface FormInfogramEmbedProps extends BaseLeadFormProps {
  infogramId: string;
}
type MarketForm = any;

const clearFormFields = (form: MarketForm | undefined) => {
  if (form) {
    const entries = STORABLE_FIELDS.map((field) => [field, '']);
    form.setValues(Object.fromEntries(entries));
  }
};

const FormInfogramEmbed = ({
  baseUrl,
  munchkinId,
  formId,
  introText,
  infogramId,
  sidekickLookup,
  variant,
}: FormInfogramEmbedProps) => {
  const { locale } = useRouter();
  const infogramRef = useRef<HTMLDivElement>(null);
  const formRef = useRef<MarketForm | null>(null);
  const [loaded, setLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [formValuesFromCookie, setFormValuesFromCookie] = useState(() => readSubmittedFormData());

  // Load Infogram script
  useEffect(() => {
    const scriptElement = document.createElement('script');
    scriptElement.src = 'https://e.infogram.com/js/dist/embed-loader-min.js';
    scriptElement.async = true;
    scriptElement.id = 'infogram-async';

    document.body.appendChild(scriptElement);

    return () => {
      document.body.removeChild(scriptElement);
    };
  }, []);

  // Load and initialize Marketo form
  useEffect(() => {
    if (window.MktoForms2) {
      window.MktoForms2.loadForm(baseUrl, munchkinId, formId, addFieldModifiers);
      window.MktoForms2.whenReady((form: any) => {
        setIsLoading(false);

        // Store the form instance in a ref to be able to clear form fields
        formRef.current = form;

        // Populate form field values from the cookie, if available
        form.setValues(formValuesFromCookie);

        form.onValidate(() => {
          const email = form.vals().Email;
          if (!isEmailGood(email)) {
            form.submitable(false);

            // @ts-expect-error We don't have correct types for locale
            const errorMessage = invalidEmailErrorMessage[locale] ?? invalidEmailErrorMessage['en-US'];
            const emailElem = form.getFormElem().find('#Email');
            form.showErrorMessage(errorMessage, emailElem);
          } else {
            form.submitable(true);
          }
        });

        form.onSuccess(() => {
          setSuccess(true);
          logFormSubmitEvent(form.getId());

          // Show the Infogram embed
          const pageElement = document.getElementById('page-content');
          if (window['InfogramEmbeds']?.initialized && pageElement) {
            window['InfogramEmbeds']?.process();
            infogramRef.current?.classList.add('visible');
            pageElement.innerHTML = infogramRef.current?.outerHTML || '';
          }

          // Store selected form field values in a cookie
          storeSubmittedFormData(form.getValues());

          return false;
        });
      });
    }
  }, [loaded]);

  const clearFormValuesFromCookie = () => {
    clearFormFields(formRef.current);
    clearSubmittedFormData();
    setFormValuesFromCookie({}); // Update UI to not show the "Welcome back" message
  };

  return (
    <Root {...sidekick(sidekickLookup)} data-testid="FormInfogramEmbed" variant={variant}>
      <Script
        strategy="afterInteractive"
        id="marketo"
        src={`${baseUrl}/js/forms2/js/forms2.min.js`}
        onError={(e) => {
          console.error('Script failed to load', e);
        }}
        onLoad={() => {
          setLoaded(true);
        }}
      />
      <FormWrapper hidden={success} data-testid="FormInfogramEmbed-formWrapper" className={variant}>
        {introText && <IntroText {...introText} />}

        {!isLoading && formValuesFromCookie.FirstName ? (
          <KnownVisitor formValues={formValuesFromCookie} onClearFormValues={clearFormValuesFromCookie} />
        ) : null}

        {isLoading && <FormCircularProgress size={40} data-testid="FormInfogramEmbed-circularProgress" />}
        <Form id={`mktoForm_${formId}`} data-testid="FormInfogramEmbed-form" />
      </FormWrapper>

      <InfogramEmbed className="infogram-embed" data-id={infogramId} ref={infogramRef} />
    </Root>
  );
};

export default FormInfogramEmbed;

const IntroText = styled(Text, {
  name: 'FormMarketoEmbed',
  slot: 'IntroText',
})(() => ({
  '& h2:first-of-type[class*="MuiTypography-root"]': {
    paddingBottom: 0,
  },
  '& h4:first-of-type[class*="MuiTypography-root"]': {
    paddingBottom: 24,
  },
}));

const InfogramEmbed = styled('div')`
  display: none;
  &.visible {
    display: flex;
  }
`;
